import { Card, Col, Form, Row, Button, Modal } from 'react-bootstrap';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import { InformationIcon } from '../../shared/InformationIcon';
import { LockedOrChanged } from '../../shared/LockedOrChanged';
import {
  getBooleanOptions,
  getChangedInformation,
  getDragClass,
  getDragContainerClass,
  getItemStyle,
  getOptions,
  getFilteredOptions,
  getOptionsLabel,
  hasChanged,
  shouldBeLocked,
} from '../common';
import { useFieldArray } from 'react-hook-form';
import { t } from '../../../services/translationService';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { formatToSwedishDecimal, parseSwedishDecimal } from '../../../services/editBookService';

interface Props {
  errors: any;
  register: any;
  blockRef: any;
  setValue: any;
  getValues: any;
  pageVariant: string;
  lockedFields: keyValue[];
  changedProps: ChangedProperty[];
  watch: any;
  control: any;
  trigger: any;
}

export const Format = ({
  errors,
  register,
  blockRef,
  setValue,
  getValues,
  pageVariant,
  lockedFields,
  changedProps,
  watch,
  control,
  trigger,
}: Props) => {
  const mediatyp = watch('mediatyp');

  const showExtendedChangeInfo = pageVariant !== "publisher";
  const [initialRender, setInitialRender] = useState(true);
  const [tillganglighetstyp, setTillganglighetstyp] = useState('');
  const [epubsAttributState, setEpubsAttributState] = useState('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined
  );

  const [bandtyper, setBandtyper] = useState([]);
  const [selectedBandtyp, setSelectedBandtyp] = useState<any[]>();
  const [currentMediaTyp, setCurrentMediaTyp] = useState<any>({});


  const addEpubTillganglighetstyp = () => {
    if (tillganglighetstyp) {
      appendEpubTillganglighetstyp(tillganglighetstyp);
      setTillganglighetstyp('');
    }
  };

  const updateEpubTillganglighetstyp = (event: ChangeEvent<HTMLInputElement>): void => {
    let value: string = (event.target as HTMLInputElement).value;
    setTillganglighetstyp(value);
  };

  const onEpubTillganglighetstypDragEnd = (result: any) => {
    moveEpubTillganglighetstyp(result.source.index, result.destination.index);
  };

  const addEpubsAttribut = () => {
    if (epubsAttributState) {
      appendEpubsAttribut(epubsAttributState);
      setEpubsAttributState('');
    }
  };

  const updateEpubsAttribut = (event: ChangeEvent<HTMLInputElement>): void => {
    let value: string = (event.target as HTMLInputElement).value;
    setEpubsAttributState(value);
  };

  const displayModal = (index?: number) => {
    index === undefined ? appendEpubAnvandningsomrade({}) : setCurrentIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    currentIndex === undefined && removeEpubAnvandningsomrade(epubAnvandningsomrade.length - 1);
  };


  const {
    fields: epubTillganglighetstyp,
    append: appendEpubTillganglighetstyp,
    move: moveEpubTillganglighetstyp,
    remove: removeEpubTillganglighetstyp,
  } = useFieldArray({
    control,
    name: 'epubTillganglighetsTyp',
  });

  const {
    fields: epubsAttribut,
    append: appendEpubsAttribut,
    remove: removeEpubsAttribut,
  } = useFieldArray({
    control,
    name: 'epubsAttribut',
  });

  const {
    fields: epubAnvandningsomrade,
    append: appendEpubAnvandningsomrade,
    update: updateEpubAnvandningsomrade,
    remove: removeEpubAnvandningsomrade,
  } = useFieldArray({
    control,
    name: 'epubAnvandningsomrade'
  });

  const saveEpubAnvandningsomrade = () =>
    trigger(
      `epubAnvandningsomrade.${currentIndex !== undefined ? currentIndex : epubAnvandningsomrade.length - 1
      }`
    ).then((validResult: boolean) => {
      if (validResult) {

        const last = currentIndex === undefined
          ? getValues(`epubAnvandningsomrade.${epubAnvandningsomrade.length - 1}`)
          : getValues(`epubAnvandningsomrade.${currentIndex}`);

        currentIndex === undefined && removeEpubAnvandningsomrade(epubAnvandningsomrade.length - 1);
        currentIndex !== undefined
          ? updateEpubAnvandningsomrade(
            currentIndex,
            {
              ...last
            }
          )
          : appendEpubAnvandningsomrade({
            ...last
          });

        setShowModal(false);
      }
    });

  // On switch media type, reset formats
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      setValue('bandtyp', undefined);
      setSelectedBandtyp(undefined);
      setValue('epubversion', undefined);
    }

    const selectedMediaTyp = Object.values(
      window.bokinfo.dictionaries.mediaTypes
    ).find((m: any) => m.value === mediatyp) as any;

    selectedMediaTyp && setCurrentMediaTyp(selectedMediaTyp);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediatyp]);

  useEffect(() => {
    const properties = ["bandtyp", "viktExklusiveForpackning"]

    // Use Object.fromEntries to create an object from key-value pairs
    // Map over the properties array to create an array of [key, value] pairs
    const values = Object.fromEntries(properties.map((property, index) => [property, getValues(properties)[index]]));

    // Bandtyp is set as string value on book, but is used as key/value in select & typeahead
    // so we need to set it as an array on mount
    if (values.bandtyp) {
      if (Array.isArray(values.bandtyp)) {
        setSelectedBandtyp(values.bandtyp)
      }
      else {
        setSelectedBandtyp([values.bandtyp])
      }
    }
    
    setValue("viktExklusiveForpackning", formatToSwedishDecimal(values.viktExklusiveForpackning))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // This will fire on any change to the field, but will only contain a value when something is actually selected.
  const onTypeaheadSelect = (value: any) => {
    setSelectedBandtyp(value);
    if (value?.length && value[0]?.value) {
      setValue("bandtyp", value[0].value)
    }
  }


  const getBandTypes = () => {
    if (mediatyp !== '' && mediatyp !== undefined) {
      const list = window.bokinfo.dictionaries['mediaTypes'];
      const result = list.filter((obj: any) => obj.key === mediatyp);

      return result;
    }
  }

  // Select component options
  const getBandtypOptions = () => {
    if (mediatyp !== '' && mediatyp !== undefined) {
      const result = getBandTypes();

      if (result !== undefined && result.length > 0) {
        let bandTyper = result[0].formats.slice(0);
        bandTyper.unshift({
          key: '',
          value: t('valjEllips'),
        });
        return bandTyper.map((entry: any, index: number) => {
          return index === 0 ? (
            <option key={null} value={''}>
              {entry.value}
            </option>
          ) : (
            <option key={entry.key} value={entry.key}>
              {entry.value}
            </option>
          );
        });
      }
    } else {
      return (
        <option key={''} value={''}>
          {t('valjEllips')}
        </option>
      );
    }
  };

  // Typeahead component options
  const getSuggestions = useCallback(
    (queryString: string): void => {

      const result = queryString.length
        ? currentMediaTyp.formats.filter((t: any) => t.value.toLowerCase().includes(queryString.toLowerCase()))
        : currentMediaTyp.formats;

      setBandtyper(result);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentMediaTyp]
  );

  return (
    <>
      <h3 ref={blockRef}>{t('format')}</h3>
      <Card className="nopadding my-4">
        <Card.Body className="p-3">
          <Row>
            {pageVariant !== 'quick' && (
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 text-body">
                    {t('mediatyp')}*
                  </Form.Label>
                  <LockedOrChanged
                    locked={shouldBeLocked(lockedFields, 'mediatyp')}
                    changed={hasChanged(changedProps, 'mediatyp')}
                    showExtendedInfo={showExtendedChangeInfo}
                    changedInformation={getChangedInformation(
                      changedProps,
                      'mediatyp'
                    )}
                    child={
                      <Form.Control
                        as={'select'}
                        type="select"
                        className="form-select"
                        placeholder={t('valjEllips')}
                        {...register('mediatyp', {
                          required: t('valideringMediatyp'),
                        })}
                        isInvalid={!!errors.mediatyp}
                      >
                        {getOptions('mediaTypes')}
                      </Form.Control>
                    }
                  />

                  {errors.mediatyp && (
                    <Form.Control.Feedback type="invalid">
                      {`${errors.mediatyp.message}`}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            )}
            <Col xs={12}>
              {(currentMediaTyp?.allowText || currentMediaTyp.formats?.length > 0) &&
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 text-body">
                    {t('bandtyp')}{currentMediaTyp.requireFormat && "*"}
                    <InformationIcon
                      text={t('hjalpBandtyp')}
                      inline={true}
                      className="ps-1"
                    />
                  </Form.Label>
                  <LockedOrChanged
                    locked={shouldBeLocked(lockedFields, 'bandtyp')}
                    changed={hasChanged(changedProps, 'bandtyp')}
                    showExtendedInfo={showExtendedChangeInfo}
                    changedInformation={getChangedInformation(
                      changedProps,
                      'bandtyp'
                    )}
                    child={
                      (
                        <>
                          {currentMediaTyp?.allowText && !currentMediaTyp.formats?.length && (
                            <Form.Control
                              {...register('bandtyp', {
                                required: { value: currentMediaTyp.requireFormat, message: t('valideringBandtyp') }
                              })}
                              placeholder={t('skrivEllips')}
                            />)}

                          {currentMediaTyp?.formats?.length > 0 && !currentMediaTyp.allowText && (
                            <Form.Control
                              as={'select'}
                              type="select"
                              {...register('bandtyp', {
                                required: { value: currentMediaTyp.requireFormat, message: t('valideringBandtyp') }
                              })}
                              className="form-select"
                              placeholder={t('valjEllips')}
                              isInvalid={!!errors.bandtyp}
                            >
                              {getBandtypOptions()}
                            </Form.Control>)}

                          {currentMediaTyp?.allowText && currentMediaTyp.formats?.length > 0 && (
                            <AsyncTypeahead
                              id="bandtypTypeahead"
                              inputProps={{ required: currentMediaTyp.requireFormat }}
                              labelKey={'value'}
                              isLoading={false}
                              options={bandtyper}
                              disabled={shouldBeLocked(lockedFields, 'bandtyp')}
                              placeholder={t('skrivEllips')}
                              selected={selectedBandtyp}
                              minLength={0}
                              allowNew={true}
                              newSelectionPrefix={t('anvand')}
                              onFocus={() => {
                                getSuggestions("");
                              }}
                              onSearch={(query: string) => {
                                getSuggestions(query);
                              }}
                              onChange={(selected: any) => {
                                onTypeaheadSelect(selected);
                              }}
                            />)}
                        </>
                      )
                    }
                  />
                  {errors.bandtyp && (
                    <Form.Control.Feedback type="invalid">
                      {`${errors.bandtyp.message}`}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              }
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('omfang')}
                </Form.Label>
                <LockedOrChanged
                  locked={shouldBeLocked(lockedFields, 'omfang')}
                  changed={hasChanged(changedProps, 'omfang')}
                  showExtendedInfo={showExtendedChangeInfo}
                  changedInformation={getChangedInformation(
                    changedProps,
                    'omfang'
                  )}
                  child={
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('omfang', {
                        setValueAs: (value: any) => !!value ? value : null
                      })}
                      isInvalid={!!errors.omfang}
                    />
                  } />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('omfangTyp')}
                </Form.Label>
                <LockedOrChanged
                  locked={shouldBeLocked(lockedFields, 'omfangTyp')}
                  changed={hasChanged(changedProps, 'omfangTyp')}
                  showExtendedInfo={showExtendedChangeInfo}
                  changedInformation={getChangedInformation(
                    changedProps,
                    'omfangTyp'
                  )}
                  child={
                    <Form.Control
                      as={'select'}
                      defaultValue={getValues('omfangTyp')}
                      type="select"
                      className="form-select"
                      placeholder={t('valjEllips')}
                      {...register('omfangTyp')}
                      isInvalid={!!errors.omfangTyp}
                    >
                      {getOptions('rangeTypes', true)}
                    </Form.Control>
                  }
                />
              </Form.Group>
            </Col>
            {pageVariant !== 'quick' && mediatyp === 'Nedladdningsbar bok' && (
              <>
                <Col xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('epubversion')}
                    </Form.Label>
                    <Form.Control
                      as={'select'}
                      type="select"
                      className="form-select"
                      placeholder={t('valjEllips')}
                      {...register('epubversion')}
                      isInvalid={!!errors.epubversion}
                    >
                      {getOptions('epubVersions')}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('epubTillganglighetsBeskrivning')}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('epubTillganglighetsbeskrivning')}
                      isInvalid={!!errors.epubTillganglighetsbeskrivning}
                    />
                  </Form.Group>
                  <div className="d-flex align-items-end w-100">
                    <Form.Group as={Col} className="mb-3 col me-3">
                      <Form.Label className="mb-1 text-body">
                        {t("epubTillganglighetsTyp")}
                        <InformationIcon
                          text={t('hjalpEpubTillganglighetsTyp')}
                          inline
                          className="ps-1"
                        />
                      </Form.Label>
                      <Form.Control
                        as={'select'}
                        type="select"
                        className="form-select"
                        placeholder={t('valjEllips')}
                        value={tillganglighetstyp}
                        onChange={updateEpubTillganglighetstyp}
                      >
                        {getFilteredOptions('epubAccessibilityTypes', getValues('epubTillganglighetsTyp'))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3 col-auto">
                      <Button
                        className="primary"
                        type="button"
                        onClick={addEpubTillganglighetstyp}
                      >
                        <i className="bi bi-plus"></i>
                      </Button>
                    </Form.Group>
                  </div>
                  <DragDropContext onDragEnd={onEpubTillganglighetstypDragEnd}>
                    <Droppable droppableId="droppableepubtillganglighetstyp">
                      {(provided: any, snapshot: any) => (
                        <Col
                          xs={12}
                          className={`mb-3 ${getDragContainerClass(
                            snapshot.isDraggingOver
                          )}`}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {epubTillganglighetstyp.map((row: any, index: number) => {
                            return (
                              <Draggable
                                key={row.id}
                                draggableId={row.id}
                                index={index}
                              >
                                {(provided: any, snapshot: any) => (
                                  <Row
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    key={row.id}
                                    className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                      } mt-2 p-1 ${getDragClass(snapshot.isDragging)}`}
                                  >
                                    <Col
                                      xs={11}
                                      className="d-flex align-items-center"
                                    >
                                      {getOptionsLabel("epubAccessibilityTypes", getValues(`epubTillganglighetsTyp.${index}`))}
                                    </Col>
                                    <Col xs={1} className="d-flex align-items-center">
                                      <i
                                        className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                        onClick={() => removeEpubTillganglighetstyp(index)}
                                      />
                                    </Col>
                                  </Row>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </Col>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <hr className="u-text-grey" />
                  <div className="d-flex align-items-end w-100">
                    <Form.Group as={Col} className="mb-3 col me-3">
                      <Form.Label className="mb-1 text-body">
                        {t("epubsAttribut")}
                        <InformationIcon
                          text={t('hjalpEpubsAttribut')}
                          inline
                          className="ps-1"
                        />
                      </Form.Label>
                      <Form.Control
                        as={'select'}
                        type="select"
                        className="form-select"
                        placeholder={t('valjEllips')}
                        value={epubsAttributState}
                        onChange={updateEpubsAttribut}
                      >
                        {getFilteredOptions('epubAttributes', getValues('epubsAttribut'))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3 col-auto">
                      <Button
                        className="primary"
                        type="button"
                        onClick={addEpubsAttribut}
                      >
                        <i className="bi bi-plus"></i>
                      </Button>
                    </Form.Group>
                  </div>
                  <DragDropContext onDragEnd={onEpubTillganglighetstypDragEnd}>
                    <Droppable droppableId="droppableepubsattribut">
                      {(provided: any, snapshot: any) => (
                        <Col
                          xs={12}
                          className={`mb-3 ${getDragContainerClass(
                            snapshot.isDraggingOver
                          )}`}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {epubsAttribut.map((row: any, index: number) => {
                            return (
                              <Draggable
                                key={row.id}
                                draggableId={row.id}
                                index={index}
                              >
                                {(provided: any, snapshot: any) => (
                                  <Row
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    key={row.id}
                                    className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                      } mt-2 p-1 ${getDragClass(snapshot.isDragging)}`}
                                  >
                                    <Col
                                      xs={11}
                                      className="d-flex align-items-center"
                                    >
                                      {getOptionsLabel("epubAttributes", getValues(`epubsAttribut.${index}`))}
                                    </Col>
                                    <Col xs={1} className="d-flex align-items-center">
                                      <i
                                        className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                        onClick={() => removeEpubsAttribut(index)}
                                      />
                                    </Col>
                                  </Row>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </Col>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Col>

                <Col xs={12}>
                  <hr className="u-text-grey" />
                </Col>
                <Col xs={12}>
                  <Form.Label className="mb-1 text-body">
                    {t('epubsAnvandningsomraden')}
                    <InformationIcon
                      text={t('hjalpInnehall')}
                      inline
                      className="ps-1"
                    />
                  </Form.Label>
                  <Card className="my-4 nopadding">
                    <Card.Body className="">
                      {epubAnvandningsomrade?.length > 0 ? (
                        <div className='mb-3 w-100 table'>
                          <div>
                            <div className="small px-3 py-3 d-flex">
                              <div style={{ width: "30%" }}>
                                <span className="d-block">{t("kod")}</span>
                              </div>
                              <div style={{ width: "20%" }}>
                                <span className="d-block">{t("beskrivning")}</span>
                              </div>
                              <div style={{ width: "50%" }}>
                                <span className="d-block"></span>
                              </div>
                            </div>
                          </div>
                          <div>
                            {epubAnvandningsomrade.map((row: any, index: number) => {
                              return (
                                <div key={row.id} className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                  } d-flex gx-0 px-3 u-text-small-medium u-text-grey py-3 align-items-center`}>
                                  <div style={{ width: "30%" }}>
                                    {getOptionsLabel("epubUses", row.key)}
                                  </div>
                                  <div style={{ width: "20%" }}>
                                    {getOptionsLabel("epubStatuses", row.value)}
                                  </div>
                                  <div style={{ width: "50%" }}>
                                    <div className="d-flex gap-3 justify-content-end">
                                      <i
                                        className="bi bi-pencil u-text-primary u-cursor-pointer"
                                        onClick={() => displayModal(index)}
                                      />
                                      <i
                                        className="bi bi-trash3 u-text-error u-cursor-pointer"
                                        onClick={() => removeEpubAnvandningsomrade(index)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        epubAnvandningsomrade.length === 0 && (
                          <p className="text-muted p-3">Inget tillagt</p>
                        )
                      )}
                    </Card.Body>
                    <Card.Footer>
                      <Button
                        type="button"
                        className="primary"
                        onClick={() => displayModal()}
                      >
                        <i className="bi bi-plus" />
                        {t("laggTill")}
                      </Button>
                    </Card.Footer>
                  </Card>
                  <Modal
                    show={showModal}
                    centered
                    size="lg"
                    onHide={closeModal}
                    onExited={() => setCurrentIndex(undefined)}
                  >
                    <Modal.Header>
                      <p>{t("laggTill")}</p>
                    </Modal.Header>
                    <Modal.Body>
                      {epubAnvandningsomrade.map((row: any, index: number) => {
                        return (
                          index ===
                          (currentIndex !== undefined
                            ? currentIndex
                            : epubAnvandningsomrade.length - 1) && (
                            <Row key={row.id}>
                              <Col xs={12}>
                                <Form.Group className="mb-3">
                                  <Form.Label className="mb-1 text-body">
                                    {t("kod")}
                                  </Form.Label>
                                  <Form.Control
                                    as={'select'}
                                    type="select"
                                    className="form-select"
                                    placeholder={t('valjEllips')}
                                    {...register(`epubAnvandningsomrade.${index}.key`)}
                                    isInvalid={
                                      !!errors.innehall && errors.innehall[index].primary
                                    }
                                  >
                                    {getFilteredOptions("epubUses", getValues("epubAnvandningsomrade.key"))}
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label className="mb-1 text-body">
                                    {t("status")}
                                  </Form.Label>
                                  <Form.Control
                                    as={'select'}
                                    type="select"
                                    className="form-select"
                                    placeholder={t('valjEllips')}
                                    {...register(`epubAnvandningsomrade.${index}.value`, { required: "" })}
                                    isInvalid={
                                      !!errors.innehall && errors.innehall[index].key
                                    }
                                  >
                                    {getOptions("epubStatuses")}
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                            </Row>
                          )
                        );
                      })}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={closeModal}>
                        {t('avbryt')}
                      </Button>
                      <Button variant="primary" onClick={saveEpubAnvandningsomrade}>
                        {t('spara')}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </>
            )}
            <Col xs={12}>
              <hr className="u-text-grey" />
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('illustrerad')}
                </Form.Label>
                <LockedOrChanged
                  locked={shouldBeLocked(lockedFields, 'illustrerad')}
                  changed={hasChanged(changedProps, 'illustrerad')}
                  showExtendedInfo={showExtendedChangeInfo}
                  changedInformation={getChangedInformation(
                    changedProps,
                    'illustrerad'
                  )}
                  child={
                    <Form.Control
                      as={'select'}
                      type="select"
                      className="form-select"
                      placeholder={t('valjEllips')}
                      {...register('illustrerad', {
                        setValueAs: (v: any) => (v === 'true' || v === true)
                      })}
                      isInvalid={!!errors.illustrerad}
                    >
                      {getBooleanOptions()}
                    </Form.Control>
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('omslagsfarg')}
                </Form.Label>
                <LockedOrChanged
                  locked={shouldBeLocked(lockedFields, 'omslagsfarg')}
                  changed={hasChanged(changedProps, 'omslagsfarg')}
                  showExtendedInfo={showExtendedChangeInfo}
                  changedInformation={getChangedInformation(
                    changedProps,
                    'omslagsfarg'
                  )}
                  child={
                    <Form.Control
                      as={'select'}
                      type="select"
                      className="form-select"
                      placeholder={t('valjEllips')}
                      {...register('omslagsfarg')}
                      isInvalid={!!errors.omslagsfarg}
                    >
                      {getOptions('coverColors')}
                    </Form.Control>
                  }
                />
              </Form.Group>
            </Col>
            {pageVariant !== 'quick' && (
              <>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('bredd')}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('bredd', {
                        setValueAs: (value: any) => !!value ? value : null
                      })}
                      isInvalid={!!errors.bredd}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('hojd')}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('hojd', {
                        setValueAs: (value: any) => !!value ? value : null
                      })}
                      isInvalid={!!errors.hojd}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('ryggbredd')}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('ryggbredd', {
                        setValueAs: (value: any) => !!value ? value : null
                      })}
                      isInvalid={!!errors.ryggbredd}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('vikt')}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('vikt', {
                        setValueAs: (value: any) => !!value ? value : null
                      })}
                      isInvalid={!!errors.vikt}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t("viktExklusiveForpackning")}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('viktExklusiveForpackning', {
                        setValueAs: (value: any) => !!value ? parseSwedishDecimal(value) : null
                      })}
                      isInvalid={!!errors.viktExklusiveForpackning}
                    />
                  </Form.Group>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
